var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { createSlice, unwrapResult, } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';
import isEqual from 'lodash/isEqual';
import { defaultBounds, distanceSortKey, DEFAULT_SORT_KEY, } from '@nomady/shared/marketplace-custom-config';
import { stringify } from '@nomady/shared/utils/urlHelpers';
import config from '../config';
import getApiParams from '../util/search-query/getApiParams';
import { searchListingsRequest, searchListingsSuccess, searchListingsError, setPage, } from '../containers/SearchPage/SearchPageSlice';
import { addMarketplaceEntities } from '../ducks/marketplaceData.duck';
import { storableError } from '../util/errors';
import { defaultLocation } from '../default-location-searches';
import SearchBarSheetVariant from '../styled-components/SearchBarSheet/SearchBarSheetVariant';
import { getBoundsFromListings } from '../util/maps';
import { localeSelector, setDisableScroll, setMenuOpen } from './UISlice';
import GeolocationSource from '../util/GeolocationSource';
import getQueryStringFromState from '../util/search-query/getQueryStringFromState';
import { locationChanged } from '../slices/routingSlice';
import merge from '../util/search-query/features/deepMerge';
import { queryListings } from '../util/api';
import haversine from 'haversine';
import { fetchPlaceDetails, fetchPredictions } from './mapboxSearchSlice';
import { LOCAL_GEOCODER_ENTRY_ID_PREFIX, getLocalGeocoderEntry, getLocalGeocoderPredictions, } from '../util/localGeocoder';
import { fallbackBounds } from '../styled-components/MapboxMap/types';
import { SnackBarType, addSnackBar, } from '../components/SnackBar/SnackBarSlice';
const EXTENDED_BOUNDS_THRESHOLD = 20;
const initialLocationState = {
    location: undefined,
    searchString: undefined,
    locationResults: [],
    isFlexible: undefined,
    region: undefined,
    origin: undefined,
};
export const initialDatesState = {
    startDate: undefined,
    endDate: undefined,
};
export const initialGuestsState = {
    seats: 1,
    adults: 1,
    teens: undefined,
    children: undefined,
    infants: undefined,
    dogs: undefined,
};
export const initialCampStyleState = {
    category: [],
    private: undefined,
};
const initialShowerState = {
    regularShower: undefined,
    outdoorShower: undefined,
};
const initialFeaturesBookingAndCancellationState = {
    instantBooking: undefined,
    cancellationPolicyFlex: undefined,
    cancellationPolicySuperflex: undefined,
    verifiedHost: undefined,
    flexibleCancellation: undefined,
};
const initialFireplaceState = {
    grate: undefined,
    firewoodIncluded: undefined,
    firewoodSale: undefined,
};
const initialActivitiesState = {
    summer: {
        biking: undefined,
        hiking: undefined,
        climbing: undefined,
        canoeing: undefined,
        swimming: undefined,
        fishing: undefined,
        observeWildAnimals: undefined,
    },
    winter: {
        skiing: undefined,
        langlauf: undefined,
        crossCountrySkiing: undefined,
        skiTouring: undefined,
        snowHiking: undefined,
        hockey: undefined,
        iceRink: undefined,
        sledding: undefined,
        buildingIgloos: undefined,
    },
};
const initialAmbianceState = {
    shadowy: undefined,
    forest: undefined,
    lawn: undefined,
    riverOrCreek: undefined,
    lake: undefined,
    mountains: undefined,
    farm: undefined,
    restaurant: undefined,
    road: undefined,
};
const initialAccessState = {
    vehicles: 0,
    access: {
        regularVehicle: undefined,
        groundClearanceSuggested: undefined,
        offRoader: undefined,
    },
    ground: {
        lawn: undefined,
        tarmac: undefined,
        gravelOrChippedWood: undefined,
    },
};
const initialFacilitiesState = {
    basics: {
        water: undefined,
        powerSupply: undefined,
        toilet: {
            regularToilet: undefined,
            portableToilet: undefined,
            closeToCampsite: undefined,
        },
    },
    accommodation: {
        millionStars: undefined,
        heating: undefined,
        linen: undefined,
    },
    shower: initialShowerState,
    miscellaneous: {
        kitchen: undefined,
        picnicTable: undefined,
        bin: undefined,
        dishwashing: undefined,
        grayWaterDisposal: undefined,
        shelter: undefined,
        internetHotspot: undefined,
        mobileReception: undefined,
    },
    store: undefined,
    winterCamping: undefined,
};
export const initialFeaturesState = {
    bookingAndCancellation: initialFeaturesBookingAndCancellationState,
    fireplace: initialFireplaceState,
    activities: initialActivitiesState,
    price: { price: undefined, currency: undefined },
    facilities: initialFacilitiesState,
    ambiance: initialAmbianceState,
    access: initialAccessState,
    campStyle: initialCampStyleState,
};
const initialSearchQuery = {
    location: initialLocationState,
    guests: initialGuestsState,
    dates: initialDatesState,
    features: initialFeaturesState,
    sort: DEFAULT_SORT_KEY,
};
const initialState = {
    currentSearchQuery: initialSearchQuery,
    isOpen: false,
    activeSearchBarSheet: undefined,
    locationSearchInProgress: false,
    storeSearchQueryInSearchParams: false,
    mapSearchEnabled: false,
    needsRefetchOfListingResults: false,
};
export const listingSearchSlice = createSlice({
    name: 'app/ListingSearch',
    initialState,
    reducers: {
        resetSearchQuery: () => {
            return initialState;
        },
        setActiveSearchBarSheet: (state, action) => {
            state.isOpen = true;
            state.activeSearchBarSheet = action.payload;
        },
        setIsOpen: (state, action) => {
            state.isOpen = action.payload;
        },
        setLocation: (state, action) => {
            state.currentSearchQuery = Object.assign(Object.assign({}, state.currentSearchQuery), { sort: state.currentSearchQuery.sort || DEFAULT_SORT_KEY, location: Object.assign(Object.assign({}, state.currentSearchQuery.location), { location: action.payload, isFlexible: undefined, region: undefined }) });
        },
        setLocationBounds: (state, action) => {
            state.currentSearchQuery.location.location = Object.assign(Object.assign({}, state.currentSearchQuery.location.location), { bounds: action.payload });
        },
        setOrigin: (state, action) => {
            state.currentSearchQuery = Object.assign(Object.assign({}, state.currentSearchQuery), { location: Object.assign(Object.assign({}, state.currentSearchQuery.location), { origin: action.payload, isFlexible: undefined, region: undefined }) });
        },
        resetOrigin: state => {
            state.currentSearchQuery = Object.assign(Object.assign({}, state.currentSearchQuery), { location: Object.assign(Object.assign({}, state.currentSearchQuery.location), { origin: undefined }) });
        },
        setLocationFlexible: (state, action) => {
            state.currentSearchQuery = Object.assign(Object.assign({}, state.currentSearchQuery), { sort: DEFAULT_SORT_KEY, location: Object.assign(Object.assign({}, state.currentSearchQuery.location), { location: undefined, region: undefined, origin: undefined, isFlexible: action.payload }) });
        },
        resetRegion: state => {
            state.currentSearchQuery.location.region = undefined;
        },
        resetLocation: state => {
            state.currentSearchQuery.location = initialLocationState;
        },
        setLocationSearchInProgress: (state, action) => {
            state.locationSearchInProgress = action.payload;
        },
        setLocationSearchResults: (state, action) => {
            state.currentSearchQuery.location = Object.assign(Object.assign({}, state.currentSearchQuery.location), action.payload);
            state.locationSearchInProgress = false;
        },
        setStartDate: (state, action) => {
            state.currentSearchQuery.dates.startDate = action.payload;
            state.currentSearchQuery.dates.endDate = undefined;
        },
        setEndDate: (state, action) => {
            state.currentSearchQuery.dates.endDate = action.payload;
        },
        resetDates: state => {
            state.currentSearchQuery.dates = initialDatesState;
        },
        setGuests: (state, action) => {
            state.currentSearchQuery.guests = Object.assign(Object.assign({}, state.currentSearchQuery.guests), action.payload);
        },
        resetGuests: state => {
            state.currentSearchQuery.guests = initialGuestsState;
        },
        setCampCategory: (state, action) => {
            var _a, _b;
            const category = (_b = (_a = state.currentSearchQuery.features) === null || _a === void 0 ? void 0 : _a.campStyle) === null || _b === void 0 ? void 0 : _b.category;
            let newCategory = category ? [...category] : [];
            const itemAlreadySelected = newCategory.includes(action.payload);
            if (itemAlreadySelected) {
                newCategory = (category || []).filter(element => element !== action.payload);
            }
            else {
                newCategory.push(action.payload);
            }
            state.currentSearchQuery.features = Object.assign(Object.assign({}, state.currentSearchQuery.features), { campStyle: Object.assign(Object.assign({}, state.currentSearchQuery.features.campStyle), { category: newCategory }) });
        },
        setPrivate: (state, action) => {
            state.currentSearchQuery.features = Object.assign(Object.assign({}, state.currentSearchQuery.features), { campStyle: Object.assign(Object.assign({}, state.currentSearchQuery.features.campStyle), { private: action.payload || undefined }) });
        },
        setFeatures: (state, action) => {
            var _a, _b, _c, _d, _e, _f;
            const oldFeatures = cloneDeep(state.currentSearchQuery.features);
            const features = merge(oldFeatures || {}, action.payload);
            if (((_b = (_a = action.payload) === null || _a === void 0 ? void 0 : _a.price) === null || _b === void 0 ? void 0 : _b.currency) === undefined &&
                ((_c = features.price) === null || _c === void 0 ? void 0 : _c.currency)) {
                features.price.currency = undefined;
            }
            if (!((_e = (_d = action.payload) === null || _d === void 0 ? void 0 : _d.price) === null || _e === void 0 ? void 0 : _e.price) && ((_f = features.price) === null || _f === void 0 ? void 0 : _f.price)) {
                features.price.price = undefined;
            }
            state.currentSearchQuery.features = features;
        },
        resetFeatures: state => {
            state.currentSearchQuery.features = initialFeaturesState;
        },
        setSearchResultsCount: (state, action) => {
            state.searchResultsCount = action.payload;
        },
        setSort: (state, action) => {
            state.currentSearchQuery.sort = action.payload;
        },
        setSearchQuery: (state, action) => {
            state.currentSearchQuery = {
                location: action.payload.location || {},
                guests: action.payload.guests || {},
                dates: action.payload.dates || {},
                features: action.payload.features || {},
                sort: action.payload.sort || '',
            };
        },
        setStoreSearchQueryInSearchParams: (state, action) => {
            state.storeSearchQueryInSearchParams = action.payload;
        },
        setMapSearchEnabled: (state, action) => {
            state.mapSearchEnabled = action.payload;
        },
        setNeedsRefetchOfListingResults: (state, action) => {
            state.needsRefetchOfListingResults = action.payload;
        },
    },
});
export const { resetSearchQuery, setActiveSearchBarSheet, setIsOpen, setLocation, setLocationFlexible, setLocationBounds, setOrigin, resetOrigin, resetRegion, resetLocation, setLocationSearchInProgress, setLocationSearchResults, setStartDate, setEndDate, resetDates, setGuests, resetGuests, setCampCategory, setPrivate, setFeatures, resetFeatures, setSearchResultsCount, setSort, setSearchQuery, setStoreSearchQueryInSearchParams, setMapSearchEnabled, setNeedsRefetchOfListingResults, } = listingSearchSlice.actions;
export const updateLocationSearchResults = (options) => (dispatch) => __awaiter(void 0, void 0, void 0, function* () {
    if (typeof window === 'undefined') {
        console.error('window is undefined in updateLocationSearchResults');
        return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (options.searchString !== '') {
        searchParams.set('search', options.searchString);
    }
    else {
        searchParams.delete('search');
    }
    const path = window.location.pathname;
    const query = searchParams.toString();
    const queryParams = query ? `?${query}` : '';
    const finalPath = `${path}${queryParams}`;
    window.history.replaceState({ prevTarget: finalPath }, '', finalPath);
    const location = {
        pathname: path,
        search: queryParams,
        hash: document.location.hash,
        key: '',
    };
    dispatch(locationChanged({ location, canonicalUrl: finalPath }));
    dispatch(setLocationSearchResults(options));
});
const removeSearchQueryParam = () => {
    if (typeof window === 'undefined') {
        console.error('window is undefined in removeSearchQueryParam');
        return;
    }
    const searchParams = new URLSearchParams(window.location.search);
    if (searchParams.get('search') !== '') {
        searchParams.delete('search');
        const path = window.location.pathname;
        const query = searchParams.toString();
        const queryParams = query ? `?${query}` : '';
        const finalPath = `${path}${queryParams}`;
        window.history.replaceState({ prevTarget: finalPath }, '', finalPath);
    }
};
export const openSearchBarSheet = (searchBarSheetVariant) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    if (!state.listingSearch.isOpen) {
        dispatch(setMenuOpen(false));
        dispatch(setIsOpen(true));
        dispatch(setDisableScroll(true));
    }
    dispatch(setActiveSearchBarSheet(searchBarSheetVariant));
    dispatch(setMapSearchEnabled(false));
});
export const cleanGeolocation = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    dispatch(resetOrigin());
    const state = getState();
    if (state.listingSearch.currentSearchQuery.sort === distanceSortKey) {
        dispatch(setSort(DEFAULT_SORT_KEY));
    }
});
export const queryLocationSearchResults = (search) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    if (search.length === 0) {
        return dispatch(updateLocationSearchResults({
            searchString: '',
            locationResults: [],
        }));
    }
    dispatch(setLocationSearchInProgress(true));
    // const state = getState();
    // const locale = localeSelector(state);
    // const result = await getPlacePredictions(search, sessionToken, {
    //   ...GOOGLE_MAPS_LISTING_SEARCH_CONFIG,
    //   language: locale,
    // });
    // const locationResults: ISearchResult[] = result.predictions.map(
    //   suggestion => ({
    //     label: suggestion.description,
    //     id: suggestion.place_id,
    //   })
    // );
    // // eslint-disable-next-line no-console
    // console.log('Google: ', result);
    const localPredictions = getLocalGeocoderPredictions(search, getState());
    const mapboxPredictionsAction = yield dispatch(fetchPredictions(search));
    const mapboxPredictions = unwrapResult(mapboxPredictionsAction);
    const locationResults = [
        ...localPredictions,
        ...mapboxPredictions.map(suggestion => ({
            label: suggestion.name,
            id: suggestion.mapbox_id,
        })),
    ].slice(0, 7);
    return dispatch(updateLocationSearchResults({
        searchString: search,
        locationResults,
    }));
});
const searchListings = (searchParams, locale, currentSearchQueryExtended) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const INSTANCE_ID = Math.floor(Math.random() * 1000);
    const state = getState();
    console.log(INSTANCE_ID, 'ASD triggered', 'needsRefetch', state.listingSearch.needsRefetchOfListingResults, 'searchInProgress', state.SearchPage.searchInProgress);
    if (state.SearchPage.searchInProgress) {
        dispatch(setNeedsRefetchOfListingResults(true));
        console.log(INSTANCE_ID, 'ASD cancel and needsRefetch=TRUE');
        return;
    }
    dispatch(searchListingsRequest(searchParams));
    console.log(INSTANCE_ID, 'ASD start fetching');
    removeSearchQueryParam();
    const page = ((_b = (_a = state.SearchPage) === null || _a === void 0 ? void 0 : _a.pagination) === null || _b === void 0 ? void 0 : _b.page) || 1;
    const generalParams = {
        page,
        perPage: config.custom.maxResultsPerSearchResultPage,
        include: ['author', 'images'],
        'fields.listing': [
            'title',
            'geolocation',
            'price',
            'description',
            'publicData',
            'metadata',
        ],
        'fields.user': [
            'profile.displayName',
            'profile.abbreviatedName',
            'profile.publicData',
            'profile.metadata',
        ],
        'fields.image': [
            'variants.landscape-crop',
            'variants.landscape-crop2x',
            'square-small2x',
            'variants.scaled-small',
            'variants.scaled-medium',
            'variants.scaled-xlarge',
        ],
        locale,
    };
    const params = Object.assign(Object.assign({}, searchParams), generalParams);
    const paramsExtendedBounds = Object.assign(Object.assign({}, currentSearchQueryExtended), generalParams);
    try {
        const response = yield queryListings(params);
        const needExtendedListings = ((_d = (_c = response === null || response === void 0 ? void 0 : response.data) === null || _c === void 0 ? void 0 : _c.meta) === null || _d === void 0 ? void 0 : _d.totalItems) < EXTENDED_BOUNDS_THRESHOLD;
        if (needExtendedListings) {
            const responseExtended = yield queryListings(paramsExtendedBounds);
            const originalNumberOfItems = response.data.data.length;
            response.data.data = [
                ...response.data.data,
                ...responseExtended.data.data
                    .sort((a, b) => {
                    var _a;
                    const bounds = (_a = state.listingSearch.currentSearchQuery.location.location) === null || _a === void 0 ? void 0 : _a.bounds;
                    if (!bounds) {
                        return 0;
                    }
                    const boundsCenter = {
                        latitude: (bounds.ne.lat + bounds.sw.lat) / 2,
                        longitude: (bounds.ne.lng + bounds.sw.lng) / 2,
                    };
                    const distanceToA = haversine({
                        latitude: a.attributes.geolocation.lat,
                        longitude: a.attributes.geolocation.lng,
                    }, boundsCenter, { unit: 'meter' });
                    const distanceToB = haversine({
                        latitude: b.attributes.geolocation.lat,
                        longitude: b.attributes.geolocation.lng,
                    }, boundsCenter, { unit: 'meter' });
                    return distanceToA - distanceToB;
                })
                    .filter(extendedListing => !response.data.data.some(originalListing => originalListing.id.uuid === extendedListing.id.uuid))
                    .slice(0, 50 - originalNumberOfItems),
            ];
            response.data.included = [
                ...(response.data.included || []),
                ...(responseExtended.data.included || []),
            ];
        }
        dispatch(addMarketplaceEntities(response));
        dispatch(setSearchResultsCount((_f = (_e = response === null || response === void 0 ? void 0 : response.data) === null || _e === void 0 ? void 0 : _e.meta) === null || _f === void 0 ? void 0 : _f.totalItems));
        dispatch(searchListingsSuccess(response));
        if (!((_g = state.listingSearch.currentSearchQuery.location.location) === null || _g === void 0 ? void 0 : _g.bounds)) {
            if (1 < ((_j = (_h = response === null || response === void 0 ? void 0 : response.data) === null || _h === void 0 ? void 0 : _h.meta) === null || _j === void 0 ? void 0 : _j.totalPages)) {
                dispatch(setLocationBounds(defaultBounds));
            }
            else {
                const listings = response.data.data;
                let bounds;
                if (0 < listings.length) {
                    bounds = getBoundsFromListings(listings);
                }
                else {
                    bounds = defaultLocation.bounds;
                }
                dispatch(setLocationBounds(bounds));
            }
        }
    }
    catch (e) {
        dispatch(addSnackBar({
            type: SnackBarType.ERROR,
            i18nTitleKey: 'SearchBarSheet.ErrorCard.title',
            plainText: e.message,
        }));
        dispatch(searchListingsError(storableError(e)));
        throw e;
    }
    const needToRefetch = getState().listingSearch.needsRefetchOfListingResults;
    console.log(INSTANCE_ID, 'ASD finished fetching', 'needsRefetch', needToRefetch);
    if (needToRefetch) {
        dispatch(setNeedsRefetchOfListingResults(false));
        console.log(INSTANCE_ID, 'ASD dispatch loadResults');
        dispatch(loadResults());
    }
});
export const loadResults = (options = {
    pushSearchQueryToDataLayer: true,
    imagesLimit: 100,
}) => (dispatch, getState) => {
    var _a, _b;
    const state = getState();
    const locale = localeSelector(state);
    const apiParams = getApiParams(state.listingSearch.currentSearchQuery);
    const currentSearchQueryExtended = cloneDeep(state.listingSearch.currentSearchQuery);
    if ((_a = currentSearchQueryExtended.location.location) === null || _a === void 0 ? void 0 : _a.bounds) {
        const bounds = (_b = currentSearchQueryExtended.location.location) === null || _b === void 0 ? void 0 : _b.bounds;
        const latDiff = bounds.ne.lat - bounds.sw.lat;
        const lngDiff = bounds.ne.lng - bounds.sw.lng;
        const newNeLat = bounds.ne.lat + latDiff;
        const newNeLng = bounds.ne.lng + lngDiff;
        const newSwLat = bounds.sw.lat - latDiff;
        const newSwLng = bounds.sw.lng - lngDiff;
        currentSearchQueryExtended.location.location.bounds.ne.lat =
            newNeLat > fallbackBounds.ne.lat ? fallbackBounds.ne.lat : newNeLat;
        currentSearchQueryExtended.location.location.bounds.ne.lng =
            newNeLng > fallbackBounds.ne.lng ? fallbackBounds.ne.lng : newNeLng;
        currentSearchQueryExtended.location.location.bounds.sw.lat =
            newSwLat < fallbackBounds.sw.lat ? fallbackBounds.sw.lat : newSwLat;
        currentSearchQueryExtended.location.location.bounds.sw.lng =
            newSwLng < fallbackBounds.sw.lng ? fallbackBounds.sw.lng : newSwLng;
    }
    const apiParamsExpandedSearch = getApiParams(currentSearchQueryExtended);
    if (options.pushSearchQueryToDataLayer) {
        dispatch(storeSearchQueryInSearchParams());
    }
    dispatch(searchListings(Object.assign(Object.assign({}, apiParams), { 'limit.images': options.imagesLimit }), locale, Object.assign(Object.assign({}, apiParamsExpandedSearch), { 'limit.images': options.imagesLimit })));
    dispatch(setPage(1));
};
const storeSearchQueryInSearchParams = () => (dispatch, getState) => {
    const state = getState();
    const { page } = state.SearchPage.pagination;
    const queryString = getQueryStringFromState(state);
    const stringifiedQueryString = `${stringify(queryString)}`;
    if (typeof window === 'undefined') {
        console.error('window is undefined in storeSearchQueryInSearchParams');
        return;
    }
    const path = window.location.pathname;
    let targetUrl = stringifiedQueryString !== '' ? `${path}?${stringifiedQueryString}` : '';
    if (page && page > 1) {
        targetUrl = `${targetUrl}&page=${page}`;
    }
    if (state.listingSearch.storeSearchQueryInSearchParams) {
        window.history.replaceState({
            prevTarget: targetUrl,
        }, '', targetUrl);
    }
    const location = {
        pathname: path,
        search: `?${stringifiedQueryString}`,
        hash: document.location.hash,
        key: '',
    };
    dispatch(locationChanged({ location, canonicalUrl: targetUrl }));
};
export const queryLocationDetails = (placeId, locale) => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    // const result = await getPlaceDetails(placeId, sessionToken, locale);
    // dispatch(setLocation(result));
    if (placeId.startsWith(LOCAL_GEOCODER_ENTRY_ID_PREFIX)) {
        const localGeocoderEntry = getLocalGeocoderEntry(placeId, getState());
        dispatch(setLocation({
            name: localGeocoderEntry.label,
            bounds: localGeocoderEntry.latLngBounds,
            placeId,
            location: localGeocoderEntry.center,
        }));
    }
    else {
        const mapboxResultAction = yield dispatch(fetchPlaceDetails(placeId));
        const mapboxResult = unwrapResult(mapboxResultAction);
        dispatch(setLocation(mapboxResult));
    }
    dispatch(loadResults({ pushSearchQueryToDataLayer: true, imagesLimit: 1 }));
});
export const resetCurrentSearchBarSheet = () => (dispatch, getState) => __awaiter(void 0, void 0, void 0, function* () {
    const state = getState();
    const { activeSearchBarSheet } = state.listingSearch;
    if (activeSearchBarSheet === SearchBarSheetVariant.Location) {
        return dispatch(resetLocation());
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Dates) {
        return dispatch(resetDates());
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Guests) {
        return dispatch(resetGuests());
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Features) {
        dispatch(resetFeatures());
    }
    dispatch(loadResults());
});
export var RightButtonType;
(function (RightButtonType) {
    RightButtonType["Continue"] = "SearchBarSheet.continueButton";
    RightButtonType["ShowResults"] = "SearchBarSheet.showResultsButton";
    RightButtonType["ShowAllResults"] = "SearchBarSheet.showAllCampsButton";
    RightButtonType["NoResults"] = "SearchBarSheet.noResults";
})(RightButtonType || (RightButtonType = {}));
export const getCurrentSearchInProgress = (state) => {
    var _a, _b;
    const { listingSearch: { currentSearchQuery }, } = state;
    const locationIsEmptyWithDefaultBounds = isEqual((_a = currentSearchQuery.location.location) === null || _a === void 0 ? void 0 : _a.bounds, defaultBounds) &&
        !currentSearchQuery.location.isFlexible &&
        !((_b = currentSearchQuery.location.location) === null || _b === void 0 ? void 0 : _b.address);
    return (!(locationIsEmptyWithDefaultBounds ||
        isEqual(currentSearchQuery.location, initialLocationState)) ||
        !isEqual(currentSearchQuery.dates, initialDatesState) ||
        !isEqual(currentSearchQuery.guests, initialGuestsState) ||
        !isEqual(currentSearchQuery.features, initialFeaturesState));
};
export const getShowResetAll = (state) => getCurrentSearchInProgress(state);
export const getShowReset = (state) => {
    const { activeSearchBarSheet, currentSearchQuery } = state.listingSearch;
    if (activeSearchBarSheet === SearchBarSheetVariant.Location) {
        return !isEqual(currentSearchQuery.location, initialLocationState);
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Dates) {
        return !isEqual(currentSearchQuery.dates, initialDatesState);
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Guests) {
        return !isEqual(currentSearchQuery.guests, initialGuestsState);
    }
    if (activeSearchBarSheet === SearchBarSheetVariant.Features) {
        return !isEqual(currentSearchQuery.features, initialFeaturesState);
    }
    return false;
};
export const isGeolocationUsedSelector = (state) => {
    var _a;
    return (!!((_a = state.listingSearch.currentSearchQuery.location) === null || _a === void 0 ? void 0 : _a.origin) &&
        !!state.geolocation.geolocationSource &&
        state.geolocation.geolocationSource !== GeolocationSource.Sort);
};
export const searchBarSheetOpenSelector = (state) => state.listingSearch.isOpen;
export default listingSearchSlice.reducer;
